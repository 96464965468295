export const position = {
    37:{
        positionx: 4.89,
        positiony: 0.16,
        positionz: 5.86,  
        glbScale:2
    },
    38:{
        positionx: -32,
        positiony: 0.4,
        positionz: 36, 
        glbScale:4
    },
    39:{
        positionx: 17.38,
        positiony: 0.4,
        positionz: 14.73,  
        glbScale:0.25
    },
    40:{
        positionx: 1,
        positiony: 0.4,
        positionz: 20,  
        glbScale:3
    },
    41:{
        positionx: -24,
        positiony: 0.4,
        positionz: 7.34,  
        glbScale:0.5

    },
    42:{
        positionx: 30,
        positiony: 0.4,
        positionz: -1,
        glbScale:2
  
    },
    43:{
        positionx: 0,
        positiony: 1.26,
        positionz: 0.26,  
        glbScale:1.5

    },
    44:{            
        positionx: 17.27,
        positiony: 1.86,
        positionz: 14.5,  
        glbScale:1.25

    },
    45:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:4

    },
    46:{
        positionx: 4.89,
        positiony: 0.16,
        positionz: 5.86,  
        glbScale:2

    },
    47:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:1

    },
    48:{
        positionx: -5,
        positiony: 0,
        positionz: 6,  
        glbScale:2

    },
    49:{
        positionx: -21,
        positiony: 0,
        positionz: -13,  
        glbScale:2

    },
    50:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:2

    },
    51:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:1.25

    },
    52:{
        positionx: 0,
        positiony: 0,
        positionz: 20,  
        glbScale:2

    },
    53:{
        positionx: 35,
        positiony: 0,
        positionz: 16,  
        glbScale:2

    },
    54:{
        positionx: -14.39,
        positiony: 0.26,
        positionz: -11.7,  
        glbScale:2

    },
    55:{
        positionx: -17.5,
        positiony: 0,
        positionz: -1,  
        glbScale:2

    },
    56:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:2

    },
    57:{
        positionx: -8,
        positiony: 0,
        positionz: -2,  
        glbScale:2

    },
    58:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:2

    },
    59:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:2

    },
    60:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:2

    }
}