import Vue from 'vue'
import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: '',
    scrollBehavior: () => ({
        y: 0
    }),
    routes: [{
        path: '/',
        component: () => import('./Home.vue'),
        title: '元宇宙展馆'
    },{
        path: '/viewModel',
        component: () => import('./ViewModel.vue')
    },{
        path: '/loadingProgram',
        component: () => import('./components/loadingProgram.vue')
    },{
        path: '/bottomNav',
        component: () => import('./components/bottomNav.vue')
    }]

})

export default router
